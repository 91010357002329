/** @jsx jsx */
import { jsx, Box, Flex, Heading, Styled, Text } from "theme-ui"
import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"
import BackgroundImage from 'gatsby-background-image'

import SEO from "../components/seo"
import IconCheese from "../images/icon-cheese.svg"
import IconAlp from "../images/icon-alp.svg"
import Arrow from "../images/arrow.svg"

const sxHeroDivider = {
  position: "relative",
  justifyContent: "center",
  alignItems: "center",
  "&:after": {
    content: `" "`,
    position: "absolute",
    top: "50%",
    left: 0,
    width: "100%",
    height: "2px",
    transform: "translateY(-1px)",
    background: "#eee",
    zIndex: -1,
  },
}
const sxNavigation = {
  flexDirection: "column",
}

const sxButton = {
  variant: "buttons.primary",
  mb: ".75rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Kuh_Gilda.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, maxHeight: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO title="Home" />
      <Flex sx={{ height: "100vh" }}>
        <Flex sx={{ height: "100%", alignItems: "center", flex: 1 }}>
          <Flex
            sx={{
              width: "85%",
              maxWidth: "27em",
              margin: "0 auto",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Heading as="h1" sx={{ mb: "1rem", fontWeight: "800", fontSize: "2.5rem" }}>
                ADOPT AN ALP®
              </Heading>
              <Text sx={{ fontSize: 3, fontWeight: 500, lineHeight: "1.25" }}>
                Support the transhumance movement in the Swiss Alps and get
                rewarded with the best artisanal cheeses in the world!
              </Text>
            </Box>
            <Flex sx={sxHeroDivider}>
              <Box sx={{ bg: "#fff" }}>
                <IconCheese sx={{ width: "4.25em" }} />
                <IconAlp sx={{ width: "4.25em" }} />
              </Box>
            </Flex>
            <Flex sx={sxNavigation}>
              <Link sx={sxButton} to="/learn">
                <Box>Learn more about the program</Box>
                <Arrow />
              </Link>
              <Link sx={sxButton} to="/alps">
                <Box>Check out the alps &amp; the cheeses</Box>
                <Arrow />
              </Link>
              <Link sx={sxButton} to="/news">
                <Box>Read the latest news from the alps</Box>
                <Arrow />
              </Link>
              <Link
                sx={sxButton}
                to="/contact"
              >
                <Box>Get in touch with us to adopt an alp!</Box>
                <Arrow />
              </Link>
            </Flex>
          </Flex>
        </Flex>
        {/* Image for desktops goes here */}
        <BackgroundImage
          Tag="section"
          sx={{display: ["none", "none", "block"], minWidth: "500px", maxWidth: "700px", width: "35%", backgroundSize: "cover"}}
          fluid={data.file.childImageSharp.fluid} />
      </Flex>
    </>
  )
}

export default IndexPage
